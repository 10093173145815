import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import * as styles from './AboutUs.module.scss';

const AboutUs: React.FC = () => {
  return (
    <section className={styles.root}>
      <div className={styles.image}>
        <StaticImage
          src='../../assets/photos/team.png'
          alt='Nasz zespół'
          className={styles.teamPhoto}
          placeholder='tracedSVG'
          objectFit='contain'
          quality={95}
        />
      </div>

      <div className={styles.content}>
        <h1 className={styles.title}>O nas</h1>

        <p className={styles.description}>
          Naszym celem jest budowanie atmosfery bez stresu. Wizyta w gabinecie powinna być doświadczeniem przyjemnym i
          miłym. Stosujemy skuteczne i sprawne znieczulenia, aby zagwarantować szybką i skuteczną wizytę.
        </p>

        <p className={styles.description}>
          <span className={styles.highlight}>Szczególnie ważne są dla nas dzieci.</span> Staramy się przede wszystkim
          nawiązać z nimi przyjacielski kontakt, który jest później bazą do wykonywania zabiegów stomatologicznych w
          spokojnej i wesołej atmosferze. Drobne nagrody zachęcają do współpracy i pozwalają im poczuć satysfakcję z
          własnej odwagi.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
